import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/Reproductor.css'
import Reproductor from "./components/Reproductor";

const Home = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 vw-100 h1 text-info">VeeMind ForEver!</div>
  )
}

function App() {
  return (
    <BrowserRouter basename="/" className="bg-black">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/abraxas/:tkn?/:brk?/:cod?/:tit?/:uid?" element={<Reproductor />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
