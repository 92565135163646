import { faClosedCaptioning, faLanguage, faPauseCircle, faPlayCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Container, Button, Row, Col, Spinner, Dropdown } from 'react-bootstrap'

let inactividad;

export const formatTime = (time) => {
    if (isNaN(time)) {
        return "00:00:00";
    }
    const date = new Date(time * 1000);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    if (hours) {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} `;
    } else return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

function Controls(props) {


    const { player, hls, videoP, titulo } = props
    const [load, setLoad] = React.useState(false);
    const [stop, setStop] = React.useState(false);
    const [show, setShow] = React.useState("visible")
    const [duracion, setDuracion] = React.useState(0)
    const [progreso, setProgreso] = React.useState(0)
    const [audLang, setAudLang] = React.useState("")
    const [subLang, setSubLang] = React.useState("")
    const [video, setVideo] = React.useState("")
    const controlRef = React.useRef()
    const mBox = React.useRef()
    const SliderBox = React.useRef()

    const btnPlay = (e) => {
        e.preventDefault();

        setStop(!stop);
        if (stop === true) {
            player.current.pause();
            setShow("visible")
            clearTimeout(inactividad)
        } else {
            player.current.play();
            flashing()
        }
    }

    const flashing = () => {
        setShow("visible")
        clearTimeout(inactividad)
        if (stop === true) {
            inactividad = setTimeout(() => {
                setShow("hidden")
            }, 5000)
        }
    }


    React.useEffect(() => {

        controlRef.current.style.visibility = show;

        function toggleWakeLock() {
            if (!navigator.wakeLock) {
                console.log('WakeLock No Permitido')
            }
            else if (window.currentWakeLock && !window.currentWakeLock.released) {
                releaseScreen();
            }
            else {
                lockScreen();
            }

        }

        async function lockScreen() {
            try {
                window.currentWakeLock = await navigator.wakeLock.request();
            }
            catch (err) {
                console.info(err);
            }
        }

        async function releaseScreen() {
            window.currentWakeLock.release();
        }

        function hideControls() {
            clearTimeout(inactividad)
            inactividad = setTimeout(() => {
                setShow("hidden")
            }, 5000)
        }

        player.current.addEventListener('pause', () => {
            toggleWakeLock()
        })

        player.current.addEventListener('loadeddata', () => {
            setLoad(true)
        })

        player.current.addEventListener('seeking', ()=>{
            setLoad(false)
        })

        player.current.addEventListener('seeked', ()=>{
            setLoad(true)
        })

        player.current.addEventListener('timeupdate', () => {
            setProgreso(player.current.currentTime)
            handleInputChange()
            SliderBox.current.value = player.current.currentTime
            try {
                setAudLang(changeText(hls.audioTrackController.currentTrack.name))
                if (hls.subtitleTrackController.trackId > -1) {
                    setSubLang(changeText(hls.subtitleTrackController.currentTrack.name))
                } else {
                    setSubLang(changeText(''))
                }
                setVideo(hls.levelController.currentLevel.name)
            } catch {
                console.info()
            }

        })
        player.current.addEventListener('progress', () => {

        })
        player.current.addEventListener('playing', () => {
            hideControls()
            toggleWakeLock()
        })

        player.current.addEventListener('canplay', () => {
            try {
                setStop(true)
                setDuracion(player.current.duration)
                setAudLang(changeText(hls.audioTrackController.currentTrack.name))
                if (hls.subtitleTrackController.trackId > -1) {
                    setSubLang(changeText(hls.subtitleTrackController.currentTrack.name))
                } else {
                    setSubLang(changeText(''))
                }
                setVideo(hls.levelController.currentLevel.name)
            } catch {
                console.info()
            }
        })

        mBox.current.addEventListener("keyup", (e) => {
            if (e.keyCode === 32) {
                setStop(!stop);
            }
        })

        SliderBox.current.addEventListener('input', (event) => {
            player.current.currentTime = event.target.value
            handleInputChange()
        })

        function handleInputChange() {
            let target = SliderBox.current
            const min = target.min
            const max = target.max
            const val = target.value
            let percentage = (val - min) * 100 / (max - min)
            target.style.backgroundSize = percentage + '% 100%'
        }


    }, [player, stop, show, mBox, hls, videoP])

    const mouseMoveHandler = () => {
        flashing()
    };

    const changeLanguage = (lang) => {
        hls.audioTrack = parseInt(lang)
    }

    const changeSubtitle = (lang) => {
        hls.subtitleTrack = parseInt(lang)
    }

    const changeVideo = (vid) => {
        hls.currentLevel = parseInt(vid)
    }

    const changeText = (texto) => {
        switch (texto) {
            case 'spa':
                return 'Español';
            case 'lat':
                return 'Latino';
            case 'eng':
                return 'Inglés';
            case 'jpn':
                return 'Japonés';
            default:
                return 'Ninguno'
        }
    }

    return (
        <Container fluid className='mainBox' ref={mBox} onMouseMove={mouseMoveHandler}>
            { !load ? <div className='loader'>
                                <Spinner animation="border" variant="danger" aria-hidden="true" style={{ width: '160px', height: '160px'}} />
                            </div> : '' }
            <div ref={controlRef}>
                <Row className='w-100 m-0 p-0 fixed-top'>
                    <Col className='bg-transparent p-3 text-light'>
                        <span className='player_title text-end'>{titulo}
                        { load ?
                            <div className='tools d-flex justify-content-center align-items-center'>
                                <Dropdown>
                                    <Dropdown.Toggle variant="transparent" id="languages" className='text-light'>
                                        <FontAwesomeIcon icon={faLanguage} className='fa-fw me-2' />{audLang}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu-dark'>
                                        {
                                            duracion > 0 ? hls.audioTracks.map((e, id) => (<Dropdown.Item key={id} onClick={(event) => { event.preventDefault(); changeLanguage(id); setAudLang(changeText(e.name)) }}>{changeText(e.name)}</Dropdown.Item>)) : ''
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="transparent" id="subtitles" className='text-light'>
                                        <FontAwesomeIcon icon={faClosedCaptioning} className='fa-fw me-2' />{subLang}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu-dark'>
                                        {
                                            duracion > 0 ? hls.subtitleTracks.map((e, id) => (<Dropdown.Item key={id} onClick={(event) => { event.preventDefault(); changeSubtitle(id); setSubLang(changeText(e.name)) }}>{changeText(e.name)}</Dropdown.Item>)) : ''
                                        }
                                        <Dropdown.Item onClick={(e) => { e.preventDefault(); changeSubtitle(-1); setSubLang("No") }}>Ninguno</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className='border-0'>
                                    <Dropdown.Toggle variant="transparent" id="videos" className='text-light'>
                                        <FontAwesomeIcon icon={faVideo} className='fa-fw me-2' />{video}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu-dark'>
                                        {
                                            duracion > 0 ? hls.levels.map((e, id) => (<Dropdown.Item key={id} onClick={(event) => { event.preventDefault(); changeVideo(id); setVideo(e.name) }}>{e.name}</Dropdown.Item>)) : ''
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            : '' }
                        </span>
                    </Col>
                </Row>
                <Row className='vw-100 text-center vh-100 justify-content-center align-items-center' onClick={(e) => { btnPlay(e) }}>
                    <Col>
                        <Button className='btnPlayer' onClick={(e) => { btnPlay(e) }}>{ !load ? '' : stop ? <FontAwesomeIcon icon={faPauseCircle} className='fa-fw fa-5x' /> : <FontAwesomeIcon icon={faPlayCircle} className='fa-fw fa-5x' />}</Button>
                    </Col>
                </Row>
                <Row className='w-100 m-0 p-0 fixed-bottom mb-10'>
                    <Col className='bg-transparent p-3 text-light'>
                        <div className='controles d-flex p-3 m-0 justify-content-center align-items-center'>
                            <div className='timecur'><span className='text-center'>{formatTime(progreso)}</span></div>
                            <div className='timerange'><input type='range' ref={SliderBox} defaultValue={progreso} min={0} max={duracion} step={1} /></div>
                            <div className='timemax'>{formatTime(duracion)}</div>
                        </div>

                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default Controls
