import React from 'react'
import Hls from '@tianfeng98/hls.js'
import { Container } from "react-bootstrap";
import Controls from "./Controls.js"
import { useParams } from "react-router-dom";


function Reproductor() {

    let { tkn, brk, cod, tit, uid } = useParams();

    const player = React.useRef()
    const videoContainer = React.useRef()
    const src = `https://brokers.veemind.uk/${brk}/${cod}/playlist.m3u8`
    const [hlsX, setHlsX] = React.useState()

    const autoPlay = true

    React.useEffect(function () {
        const hlsConfig = {
            autoStartLoad: true,
            debug: false,
            recover: -1,
            initialLiveManifestSize: 1,
            maxBufferSize: 10 * 1024 * 1024,
            maxBufferLength: 30,
            maxMaxBufferLength: 60,
            backBufferLength: 30,
            maxBufferHole: 0.5,
            highBufferWatchdogPeriod: 2,
            nudgeOffset: 0.1,
            nudgeMaxRetry: 3,
            maxFragLookUpTolerance: 0.25,
            liveSyncDurationCount: 3,
            overrideNative: true,
            liveMaxLatencyDurationCount: Infinity,
            liveDurationInfinity: false,
            preferManagedMediaSource: true,
            fragLoadPolicy: {
                default: {
                    maxTimeToFirstByteMs: 9000,
                    maxLoadTimeMs: 100000,
                    timeoutRetry: {
                        maxNumRetry: 2,
                        retryDelayMs: 0,
                        maxRetryDelayMs: 0,
                    },
                    errorRetry: {
                        maxNumRetry: 5,
                        retryDelayMs: 3000,
                        maxRetryDelayMs: 15000,
                        backoff: 'linear',
                    },
                },
            },
            startFragPrefetch: false,
            testBandwidth: false,
            progressive: false,
            lowLatencyMode: true,
            nextLoadLevel: 0,
            fpsDroppedMonitoringPeriod: 5000,
            fpsDroppedMonitoringThreshold: 0.2,
            appendErrorMaxRetry: 3,
            enableWebVTT: true,
            xhrSetup: xhr => {
                xhr.setRequestHeader('mode', 'no-cors')
                xhr.setRequestHeader('Authorization', `Bearer: ${tkn}`)
            }
        }
        if (Hls.isSupported()) {
            const hls = new Hls(hlsConfig)
            hls.loadSource(src)
            hls.attachMedia(player.current)
            hls.currentLevel = 0
            hls.audioTrack = 0
            hls.subtitleTrack = -1
            setHlsX(hls)
        }
    }, [autoPlay, player, src, tkn]);


    return (
        <Container fluid className='rep_container' ref={videoContainer}>
            <video
                ref={player}
                autoPlay
                playsInline
                preload='metadata'
                controls={false}
                width="100%"
                height="auto"
            />
            <Controls player={player} hls={hlsX} videoP={videoContainer} titulo={tit} uid={uid} />
        </Container>
    )
}

export default Reproductor